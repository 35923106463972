import {ReactElement, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {NextPage} from 'next';
import router from 'next/router';
import {checkAuth, refreshAuth} from 'library/auth';
import firebase from 'firebase/compat/app';
// Components
import {withLayout} from 'components/shared/Layout';
import Button from '@brightlive/shared/components/Button';
import {Grid, Row, Col} from '@brightlive/shared/components/Grid';
import BrightHead from '@brightlive/shared/components/BrightHead';
// Images
import GuestPhone1 from 'public/images/guest-phone-1.svg';
import GuestPhone2 from 'public/images/guest-phone-2.svg';
// Styles
import S from 'styles/Homepage/style';

const Homepage: NextPage<{
  currentUser: firebase.User | null;
}> = ({currentUser}): ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      refreshAuth(dispatch, currentUser, '/dashboard/sessions');
    }
  }, []);

  return (
    <>
      <BrightHead />
      <S.Homepage>
        <S.HeroSection>
          <S.HeroBackground />
          <S.HeroContent>
            <Grid>
              <Row align="center">
                <Col xs={4} sm={8} md={10}>
                  <S.HeroTitle>What show will you create today?</S.HeroTitle>
                </Col>
                <Col xs={4} sm={8} md={6}>
                  <S.HeroSubtitle>
                    Bright makes it easy to record professional video podcasts
                    and interviews
                  </S.HeroSubtitle>
                </Col>
                <Col xs={4} sm={8} md={10}>
                  <S.HeroButton>
                    <Button
                      type="lightning"
                      size="large"
                      href="/login?signup=true"
                      text="Get started for free"
                    />
                  </S.HeroButton>
                  <S.HeroVideo>
                    <S.Gif autoPlay loop muted playsInline>
                      <source src="/images/hero.mp4" type="video/mp4" />
                    </S.Gif>
                  </S.HeroVideo>
                </Col>
              </Row>
            </Grid>
          </S.HeroContent>
        </S.HeroSection>
        <a id="quality" />
        <S.RecordingSection>
          <Grid>
            <Row>
              <Col xs={4} sm={8} md={5} mdOffset={1} last={['MD', 'LG', 'XL']}>
                <S.RecordingText>
                  <S.SectionTitle>
                    Quality recordings in one take
                  </S.SectionTitle>
                  <S.SectionSubtitle>
                    Bright records high quality video and audio without the need
                    to spend hours in expensive editing tools. Enjoy the freedom
                    of live capturing your show in one take and getting a
                    professional result.
                  </S.SectionSubtitle>
                </S.RecordingText>
              </Col>
              <Col xs={4} sm={8} md={6}>
                <S.Gif autoPlay loop muted playsInline>
                  <source src="/images/recording.mp4" type="video/mp4" />
                </S.Gif>
              </Col>
            </Row>
          </Grid>
        </S.RecordingSection>
        <a id="audience" />
        <S.LiveAudienceSection>
          <Grid>
            <Row>
              <Col xs={4} sm={8} md={5}>
                <S.SectionTitleInverse>Live audiences</S.SectionTitleInverse>
                <S.SectionSubtitleInverse>
                  Invite your guests and fully interactive attendees with
                  friction free links, up to 150 in a session! Manage audience
                  questions easily from Bright’s question panel and bring anyone
                  into the conversation.
                </S.SectionSubtitleInverse>
              </Col>
              <Col xs={4} sm={8} md={6} mdOffset={1}>
                <S.LiveAudienceVideo>
                  <S.Gif autoPlay loop muted playsInline>
                    <source src="/images/bring-to-stage.mp4" type="video/mp4" />
                  </S.Gif>
                </S.LiveAudienceVideo>
              </Col>
            </Row>
          </Grid>
        </S.LiveAudienceSection>
        <a id="guests" />
        <S.GuestSection>
          <Grid>
            <Row>
              <Col xs={4} sm={8} md={5} mdOffset={1} last={['MD', 'LG', 'XL']}>
                <S.GuestSectionText>
                  <S.SectionTitle>Guests can join from anywhere</S.SectionTitle>
                  <S.SectionSubtitle>
                    In just a few clicks your guests and attendees can join from
                    any browser on any device, from any location.
                  </S.SectionSubtitle>
                </S.GuestSectionText>
              </Col>
              <Col xs={4} sm={8} md={6}>
                <S.GuestPhones>
                  <S.GuestPhoneWrapper>
                    <S.GuestPhone src={GuestPhone1} />
                  </S.GuestPhoneWrapper>
                  <S.GuestPhoneWrapper>
                    <S.GuestPhone src={GuestPhone2} />
                  </S.GuestPhoneWrapper>
                </S.GuestPhones>
              </Col>
            </Row>
          </Grid>
        </S.GuestSection>
        <a id="layouts" />
        <S.StageLayoutsSection>
          <Grid>
            <Row>
              <Col xs={4} sm={8} md={5}>
                <S.StageLayoutText>
                  <S.SectionTitle>Stage layouts</S.SectionTitle>
                  <S.SectionSubtitle>
                    Our dynamic stage allows you to move quickly from solo
                    speaker to layouts that support multiple guests.
                  </S.SectionSubtitle>
                </S.StageLayoutText>
              </Col>
              <Col xs={4} sm={8} md={6} mdOffset={1}>
                <S.Gif autoPlay loop muted playsInline>
                  <source src="/images/stage-layouts.mp4" type="video/mp4" />
                </S.Gif>
              </Col>
            </Row>
          </Grid>
        </S.StageLayoutsSection>
        <a id="signup" />
        <S.CTASection>
          <Grid>
            <Row>
              <Col>
                <S.CTATitle>Sign up and try Bright today</S.CTATitle>
                <Button
                  type="lightning"
                  size="large"
                  href="/login?signup=true"
                  text="Get started for free"
                />
              </Col>
            </Row>
          </Grid>
        </S.CTASection>
      </S.Homepage>
    </>
  );
};

Homepage.getInitialProps = async ctx => {
  const continueUrl = ctx.query?.continueUrl as string;
  const mode = ctx.query?.mode;
  const oobCode = ctx.query?.oobCode;

  if (mode) {
    if (mode === 'resetPassword' && oobCode && ctx.res) {
      ctx.res.writeHead(301, {
        Location: `/reset-password?oobCode=${oobCode}&mode=${mode}`,
      });
      ctx.res.end();
    } else if (mode === 'verifyEmail' && oobCode && ctx.res) {
      ctx.res.writeHead(301, {
        Location: `/verify-email?code=${oobCode}`,
      });
      ctx.res.end();
    }
    return {currentUser: null};
  }

  try {
    const currentUser = await checkAuth(ctx.store.dispatch, ctx);
    if (currentUser) {
      const isServer = typeof window === 'undefined';
      if (isServer) {
        ctx?.res?.writeHead(302, {Location: continueUrl ?? '/dashboard'});
        ctx?.res?.end();
      } else {
        router.push(continueUrl ?? '/dashboard');
      }
    }
    return {currentUser};
  } catch (err) {
    console.error(err);
  }

  return {currentUser: null};
};

export default withLayout(Homepage, {
  withFooter: true,
  withHeader: true,
  theme: 'light',
  navStyle: 'transparent',
});
