import Head from 'next/head';
import React from 'react';

export interface MetaProps {
  title?: string;
  logo?: string;
  description?: string;
}

const BrightHead = ({
  title = 'Bright - professional video podcast creation made easy',
  description = 'Bright makes it easy to create professional video podcasts and interviews, from any browser.',
  logo = `${process.env.NEXT_PUBLIC_BOOKING_URL}/images/bright-meta-image.png`,
}: MetaProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content={logo} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://brightlive.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@BrightLiveX" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logo} />
    </Head>
  );
};

export default React.memo(BrightHead);
