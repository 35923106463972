import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const SECTION_OVERLAP = '9.6vw';

const S = {
  Homepage: styled.div`
    background: ${props => props.theme.backgroundColor.body};s
  `,
  HeroSection: styled.div`
    padding: ${props =>
      `calc(${props.theme.sizing.mobile.navHeight} + ${props.theme.spacing['3XL']}) 0 ${props.theme.spacing['5XL']}`};
    background: ${props => props.theme.color.orange['200']};

    ${media.MD} {
      padding: ${props =>
        `calc(${props.theme.sizing.desktop.navHeight} + ${props.theme.spacing['3XL']}) 0 0`};
      position: relative;
    }
  `,
  HeroBackground: styled.div`
    display: none;
    ${media.LG} {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      z-index: 1;
      background-image: url('/images/homepage-background.svg');
      background-size: 3215px 1461px;
      background-position: -534px 36px;
      background-repeat: no-repeat;
      z-index: 0;
    }
  `,
  HeroContent: styled.div`
    position: relative;
    z-index: 1;
  `,
  HeroTitle: styled(Ag.DisplayLG)`
    margin-bottom: ${props => props.theme.spacing.MD};
    text-align: center;
    color: ${props => props.theme.contentColor.black};
  `,
  HeroSubtitle: styled(Ag.HeadingLG)`
    text-align: center;
    color: ${props => props.theme.contentColor.black};
  `,
  HeroButton: styled.div`
    margin: ${props => props.theme.spacing['2XL']} 0;
    text-align: center;
  `,
  HeroVideo: styled.div`
    border-radius: ${props => props.theme.borderRadius.MD};
    overflow: hidden;
    transform: translateZ(0);
  `,
  Gif: styled.video`
    display: block;
    width: 100%;
  `,
  RecordingSection: styled.div`
    padding: ${props => props.theme.spacing['5XL']} 0;
    background: ${props => props.theme.color.blue['300']};

    ${media.MD} {
      margin-top: -${SECTION_OVERLAP};
      padding: calc(${SECTION_OVERLAP} + 120px) 0 120px;
      position: relative;
    }
  `,
  RecordingText: styled.div`
    ${media.MD} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,
  SectionTitle: styled(Ag.DisplayMD)`
    color: ${props => props.theme.contentColor.black};
    margin-bottom: ${props => props.theme.spacing.MD};
  `,
  SectionSubtitle: styled(Ag.ParagraphLG)`
    color: ${props => props.theme.contentColor.black};
    margin-bottom: ${props => props.theme.spacing['2XL']};

    ${media.MD} {
      margin-bottom: 0;
    }
  `,
  SectionTitleInverse: styled(Ag.DisplayMD)`
    color: ${props => props.theme.contentColor.white};
    margin-bottom: ${props => props.theme.spacing.MD};
  `,
  SectionSubtitleInverse: styled(Ag.ParagraphLG)`
    color: ${props => props.theme.contentColor.white};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
  LiveAudienceSection: styled.div`
    padding: ${props => props.theme.spacing['5XL']} 0 0;
    background: ${props => props.theme.color.blue['700']};

    ${media.MD} {
      padding: 120px 0 0;
    }
  `,
  LiveAudienceVideo: styled.div`
    border-radius: ${props =>
      `${props.theme.borderRadius.MD} ${props.theme.borderRadius.MD} 0 0`};
    overflow: hidden;
    transform: translateZ(0);
  `,
  GuestSection: styled.div`
    padding: ${props => props.theme.spacing['5XL']} 0;
    background: ${props => props.theme.color.orange['400']};

    ${media.MD} {
      padding: ${props => props.theme.spacing['6XL']} 0 0;
      margin-bottom: -${SECTION_OVERLAP};
    }
  `,
  GuestSectionText: styled.div`
    ${media.MD} {
      height: calc(100% - ${SECTION_OVERLAP});
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 80px;
      box-sizing: border-box;
    }
  `,
  GuestPhones: styled.div`
    display: flex;
    gap: ${props => props.theme.spacing.MD};

    ${media.MD} {
      gap: ${props => props.theme.spacing.XL};
    }
  `,
  GuestPhoneWrapper: styled.div`
    flex: 1;
  `,
  GuestPhone: styled.img`
    border-radius: 20px;
    overflow: hidden;
    box-shadow: ${props => props.theme.elevation.level5};
    width: 100%;
  `,
  StageLayoutsSection: styled.div`
    padding: ${props => props.theme.spacing['5XL']} 0;
    background: ${props => props.theme.backgroundColor.body};

    ${media.MD} {
      padding: calc(${props => props.theme.spacing['6XL']} + ${SECTION_OVERLAP})
        0 120px;
    }
  `,
  StageLayoutText: styled.div`
    ${media.MD} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,
  CTASection: styled.div`
    padding: ${props => props.theme.spacing['2XL']} 0;
    text-align: center;

    ${media.MD} {
      padding: ${props => props.theme.spacing['6XL']} 0;
    }
  `,
  CTATitle: styled(Ag.HeadingLG)`
    color: ${props => props.theme.contentColor.black};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
  Loading: styled(Ag.HeadingLG)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  `,
};

export default S;
