import React, {useEffect} from 'react';
import {NextPage} from 'next';
import {Elements} from '@stripe/react-stripe-js';
import {useDispatch, connect, useSelector} from 'react-redux';
import firebase from 'firebase/compat/app';
// Components
import NavigationBar from 'components/shared/Navigation/NavigationBar';
import LoginModal from 'components/auth/LoginModal';
import LegalModal from 'components/shared/LegalModal';
import PrivateSessionModal from 'components/creator/PrivateSessionModal';
import Toast from 'components/shared/Toast';
import Footer from 'components/shared/Footer';
// Stripe
import {loadStripe} from '@stripe/stripe-js';
// Helpers
import {checkAuth, refreshAuth} from 'library/auth';
// Redux
import {BrightPageContext} from 'redux/store';
import {AuthReducer} from 'redux/auth/reducer';

export const withLayout = (
  WrappedComponent,
  options: {
    withFooter: boolean;
    withHeader: boolean;
    theme?: 'light' | 'dark';
    navStyle?: 'transparent' | 'solid';
  } = {
    withFooter: true,
    withHeader: true,
    theme: 'light',
    navStyle: 'solid',
  }
) => {
  const stripeKey = process.env.NEXT_PUBLIC_STRIPE_API_KEY ?? '';
  const stripe = loadStripe(stripeKey);

  const Wrapper: NextPage = (props: {
    children?: React.ReactNode;
    currentUser?: firebase.User;
    continueUrl?: string;
  }) => {
    const dispatch = useDispatch();
    const continueUrl =
      useSelector((state: AuthReducer) => state.auth.auth?.continueUrl) ?? '';
    // Refs
    useEffect(() => {
      refreshAuth(
        dispatch,
        props.currentUser,
        props.continueUrl ?? continueUrl
      );
    }, []);

    return (
      <Elements stripe={stripe}>
        {options.withHeader && <NavigationBar navStyle={options.navStyle} />}
        <WrappedComponent {...props} />
        {options.withFooter && <Footer />}
        <LoginModal />
        <LegalModal />
        <PrivateSessionModal />
        <Toast pageTheme={options.theme} />
      </Elements>
    );
  };

  Wrapper.getInitialProps = async (ctx: BrightPageContext) => {
    const continueUrl: string = ctx?.query.continueUrl as string;
    const currentUser = await checkAuth(ctx.store.dispatch, ctx);
    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));
    return {...componentProps, currentUser, continueUrl};
  };

  return connect(state => state)(Wrapper);
};
